import Vue from 'vue'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import ElementLocale from 'element-ui/lib/locale'
import VueI18n from 'vue-i18n'
import App from './App.vue'
import "core-js/stable";
import "regenerator-runtime/runtime";
import _ from 'lodash'
import router from './router'
import store from './store'
import {
    Input, Select, Option, Form, DatePicker, FormItem
    , Button, Radio, RadioGroup, Upload, Dialog,
    Backtop, Breadcrumb, BreadcrumbItem, Collapse,
    CollapseItem, Checkbox, CheckboxGroup, Tag, Dropdown,
    dropdownMenu, dropdownItem, Pagination, Scrollbar,
    Message, Row, Col, Carousel, CarouselItem, Loading, Slider, Table, TableColumn,Alert
} from 'element-ui';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import axios from '@/utils/request/request.js'
import qs from 'qs';
import api from '@/utils/request/api'
import VideoPlayer from 'vue-video-player'
import VueLazyload from 'vue-lazyload';
require('vue-video-player/node_modules/video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
Vue.use(VideoPlayer)
Vue.use(VueAwesomeSwiper)
Vue.use(Alert)
Vue.use(Input);
Vue.use(Button);
Vue.use(Select);
Vue.use(Option);
Vue.use(Form);
Vue.use(DatePicker);
Vue.use(FormItem);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Upload);
Vue.use(Dialog);
Vue.use(Backtop);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Tag);
Vue.use(Dropdown);
Vue.use(dropdownItem);
Vue.use(dropdownMenu);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Pagination);
Vue.use(Scrollbar);
Vue.prototype.$message = Message
Vue.use(Row);
Vue.use(Col);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Loading);
Vue.use(Slider);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(VueI18n);
Vue.use(VueLazyload, {
    preLoad: 1,
    error: require('./assets/image/loading.gif'),
    loading: require('./assets/image/loading.gif'),
    attempt: 2
})
var cn = Object.assign(require('@/utils/lang/cn'), zhLocale);
var en = Object.assign(require('@/utils/lang/en'), enLocale);
const i18n = new VueI18n({
    locale: 'cn', //语言标识
    messages: {
        'cn': cn,   // 中文语言包
        'en': en      // 英文语言包
    }
});

ElementLocale.i18n((key, value) => i18n.t(key, value))

Vue.prototype._ = _
Vue.prototype.$qs = qs;
Vue.prototype.$http = axios;
Vue.prototype.$api = api;
router.beforeEach(function (to, from, next) {
    to.meta.lastRouter = from.name
    document.body.scrollTop = 0
    // firefox
    document.documentElement.scrollTop = 0
    // safari
    window.pageYOffset = 0;
    if (!localStorage.getItem("loginInfo")) {
        if (to.name == 'Collect') {
            return next('/')
        }
    }
    if (to.matched.length == 0) {
        return next({ name: 'error-404' })
    }
    // if(to.name==="Register"||to.name==="Detail"){
    //     to.params.lang==='zh-cn'?[sessionStorage.lang='cn',i18n.locale='cn',store.commit('languageChange',2)]:[sessionStorage.lang='en',i18n.locale='en',store.commit('languageChange',1)];
    // }
    next()
});

var lang = localStorage.lang

lang ? i18n.locale = localStorage.lang : localStorage.setItem('lang', 'cn');
i18n.locale === 'en' ? store.commit('languageChange', 1) : store.commit('languageChange', 2)
var newvue = new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
window.addEventListener("popstate", function (e) {
    if (newvue.$route.name == 'ProductFPage' && newvue.$route.meta.lastRouter != 'Detail') {
        newvue.$router.push({ name: 'ProductIndex' })
    }
}, false);
export default newvue