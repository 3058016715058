<template>
  <div class="info">
    <!-- {{ product.serviceEmail }} -->
    <div class="alert">
      {{ $t('productDialog.alert[0]') }}
      <a href="https://market.azure.cn/Home" target="_blank">{{ $t('productDialog.alert[1]') }}</a>
      {{ $t('productDialog.alert[2]') }}
    </div>
    <div v-if="product.serviceEmail">
      {{ $t("Info.contact") }}：<a :href="mailto">{{ product.serviceEmail }}</a>
    </div>
    <div v-else>
      {{ $t("Info.text") }}
    </div>
    <!-- <p>{{ $t("Info.title") }}</p>
    <el-form ref="form" :model="form" :rules="rules" label-width="140px">
      <el-form-item :label="$t('Info.name')" prop="Name">
        <el-input
          v-model="form.Name"
          :placeholder="$t('feedBack.placeholder.name')"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('Info.email')" prop="Email">
        <el-input
          v-model="form.Email"
          :placeholder="$t('feedBack.placeholder.email')"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('Info.phone')" prop="Phone">
        <el-input
          v-model="form.Phone"
          :placeholder="$t('feedBack.placeholder.phone')"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('Info.company')" prop="CompanyName">
        <el-input
          v-model="form.CompanyName"
          :placeholder="$t('feedBack.placeholder.company')"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('Info.consult')" class="consult">
        <el-input
          type="textarea"
          v-model="form.Consult"
          :placeholder="$t('Info.placeholder_c')"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <div class="btn">
      <div class="submit" @click="submitBtn('form')">
        {{ $t("Info.submit") }}
      </div>
    </div> -->
  </div>
</template>

<script>
import qs from "qs";
export default {
  name: "Info",
  props: {
    product: {
      type: Object,
    },
  },
  computed: {
    mailto: function () {
      return "mailto:" + this.product.serviceEmail;
    },
  },
  data() {
    let checkEmpty = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t("register.rules.mes_1")));
      } else {
        return callback();
      }
    };
    let checkEmail = (rule, value, callback) => {
      const mailReg =
        /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      if (!value) {
        return callback(new Error(this.$t("register.rules.mes_1")));
      }
      setTimeout(() => {
        if (mailReg.test(value)) {
          callback();
        } else {
          callback(new Error(this.$t("register.rules.mes_2")));
        }
      }, 100);
    };
    return {
      form: {
        Name: "",
        Email: "",
        Phone: "",
        CompanyName: "",
        Consult: "",
        ProductId: "",
      },
      rules: {
        Name: [
          {
            required: true,
            validator: checkEmpty,
            trigger: ["blur", "change"],
          },
        ],
        Email: [
          {
            required: true,
            validator: checkEmail,
            trigger: ["blur", "change"],
          },
        ],
        Phone: [
          {
            required: true,
            validator: checkEmpty,
            trigger: ["blur", "change"],
          },
        ],
        CompanyName: [
          {
            required: true,
            validator: checkEmpty,
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    submitBtn(formName) {
      var that = this;
      this.$refs[formName].validate(async (valid) => {
        let dataSource = null;
        if (that.dataType === 2) {
          dataSource = "100000002";
        }
        if (that.dataType === 2) {
          dataSource = "100000003";
        }
        if (valid) {
          var obj = {
            dataresource: dataSource,
            name: "",
            description: "",
            annotationid: "",
            firstname: that.form.Name,
            phone: that.form.Phone,
            email: that.form.Email,
            company: that.form.CompanyName,
            servicetype: "",
            questiontype: "",
          };
          console.log(obj);
          // this.$http
          //   .post(
          //     this.$api.jarvisToken,
          //     qs.stringify({
          //       password: "123456",
          //       username: "newbizcase",
          //       grant_type: "password",
          //     })
          //   )
          //   .then((res) => {
          //     localStorage.setItem("_biz_token_", res.data.access_token);
          //     this.$http
          //       .post(this.$api.jarvisCreate, obj)
          //       .then((res) => {
          //         if (res.data.status) {
          //           this.$message({
          //             message: this.$t("register.message.success"),
          //             type: "success",
          //             customClass: "tip",
          //             offset: 385,
          //           });
          //           this.$refs["form"].resetFields();
          //           this.form.Consult = "";
          //           this.$emit("showIt", false);
          //         } else {
          //           this.$message.error(this.$t("register.message.error"));
          //         }
          //       })
          //       .catch(() => {
          //         this.$message.error(this.$t("register.message.error"));
          //       });
          //   });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tip {
  background-color: rgba(46, 121, 208, 1);
  border-color: rgba(46, 121, 208, 1);
}

.tip .el-message__icon {
  color: #fff;
}

.tip .el-message__content {
  color: #fff;
}

.info {
  padding: 0 36px;

  .alert{
    margin-bottom: 15px;
    word-wrap: break-word;
    a{
      color:#0000ee;
    }
  }
  p {
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 20px 140px;
  }

  .el-form-item {
    margin-bottom: 12px;
    height: 42px;

    /deep/.el-input {
      height: 30px;
    }

    /deep/.el-form-item__label {
      height: 30px;
    }

    /deep/ .el-input__inner {
      padding: 0 5px;
      font-size: 11px;
      height: 30px;
    }

    /deep/ .el-form-item__error {
      padding: 0;
    }

    /deep/ .el-textarea {
      height: 50px;
    }

    /deep/ .el-textarea__inner {
      padding: 2px 5px;
      height: 50px;
      font-size: 11px;
    }

    /deep/ .el-form-item__error {
      font-size: 10px;
    }
  }

  .consult /deep/ .el-form-item__label {
    padding-right: 24px;
  }

  .btn {
    margin: 30px 140px 0;
    width: 100px;
    background: rgba(46, 121, 208, 1);
    color: #fff;
    font-size: 14px;
    line-height: 28px;
    text-align: center;
    cursor: pointer;
  }
}
</style>
