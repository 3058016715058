module.exports = {
	topBanner: {
		selectItem: {
			market: "市场",
			products: "全部产品",
			b_service: "成为服务商",
			service: "服务商",
			more: "了解更多",
		},
		btn_group: {
			search: "搜索",
			lang: "EN",
			login: "登录",
			submit: "提交",
			placeholder: "在市场中搜索",
			logout: "注销",
			loginFail: "登录失败",
		},
		loginItem: {
			favourites: "我已保存的项",
		},
	},
	footerBanner: {
		BCSS: "amp-support@oe.21vianet.com",

		product: "产品和定价",
		benefit: "Azure 混合权益（Azure Hybrid Benefit）",
		document: "Azure 文档 >",
		support: "获取支持 >",
		supportPlans: "Azure 支持计划 >",

		azurePortal: "Azure 门户   >",
		enterprisePortal: "企业门户网站   >",
		accountCenter: "帐户中心   >",
		microsoftAzure: "Microsoft Azure   >",
		available: "可用产品(按区域)   >",
		status: "Azure 状态   >",
		geographies: "Azure 地域   >",
		sla: "服务级别协议 (SLA)",
		legal: "法律信息",
		icp: "ICP 备案",
		security: "公安备案",
		invoice: "申请中国税务发票   >",
		privacyStatement: "在线服务隐私声明   >",
		trustCenter: "信任中心   >",

		// azure: "Azure",
		// whatAzure: "认识Azure",
		// products: "产品",
		// pricing: "价格",
		// offerDetail: "优惠详情",
		// guidance: "中国区Azure购买指南",
		// video: "视频中心",
		// solution: "通用解决方案",
		// caase: "客户案例",
		// document: "技术文档",
		// blog: "博客",

		// support: "支持",
		// contactUs: "联系我们",
		// supportPlan: "支持计划",
		// dashboard: "服务器仪表盘",
		// legal: "法律",
		// agreement: "服务级别协议",
		// icp: "ICP备案",
		// psr: "公安备案",
		// fc: "发票",
		// faqs: "常见问题",
		// wNew: "最新公告",

		// trust: "信任中心",
		// homepage: "信任中心首页",
		// security: "安全性",
		// privacy: "隐私与管控",
		// compliance: "合规性",
		// transparency: "透明度",

		// account: "账户",
		// azurePortal: "Azure 门户",
		// aportal: "充值.订阅与账单",
		// eaportal: "企业门户网站",

		email: "支持邮箱",

		globalAzure: "访问全球版Azure",
		shicp: "沪ICP备13015306号-25",
		psb: "沪公网安备 31011502002224号",
		privacy: "隐私",

		getSupport: "快速获取支持",
		pA: "关注我们",

		followUs: "关注我们",
		weChat1: "Microsoft 云科技公众号",
		weChat2: "Azure 云技术支持公众号",
	},
	register: {
		maintainedText:
			"提交功能在北京时间2024/8/24 20:30-21:00不可用，请稍后重试。",
		agreeText1: "我已阅读并同意",
		agreeText2: "Azure 中国市场发布者协议",
		checkbox_group: {
			Professional: "IT Pro",
			Developers: "开发者",
			BusinessUsers: "商务人士",
		},
		options: {
			Service: "定制服务",
			Image: "虚拟机镜像",
			Template: "Azure资源管理器模板",
		},
		label: {
			Name: "姓名",
			Email: "邮箱",
			Phone: "手机号码",
			CompanyName: "公司名称",
			CompanyNameEnglish: "公司英文名（可选项）",
			CompanyUrl: "公司网址",
			CompanyProfiles: "公司简称",
			M_ID: "Microsoft合作伙伴网络ID",
			S_Code: "统一社会信用代码",
			License: "营业执照上传",
			S_Type: "您想要发布的服务类型",
			S_Name: "服务名称",
			S_Description: "服务描述",
			ob_User: "该服务的目标用户群",
			captcha: "验证码",
			btn: "发送",
		},
		placeholder: {
			Name: "请输入您的姓名",
			Email: "请提供您的办公邮箱",
			Phone: "请输入您的手机号码",
			CompanyName: "请输入您的公司名称（要求和营业执照保持一致）",
			CompanyNameEnglish: "请输入您的公司英文名称（如有），用于后期审核",
			CompanyUrl: "请输入您的公司网址",
			CompanyProfiles: "请填写您的公司简称",
			M_ID: "请输入您公司的Microsoft合作伙伴网络ID",
			S_Code: "请输入您公司的统一社会信用代码",
			S_Type: "请选择",
			S_Name: "请输入服务名称",
			S_Description: "请输入服务描述",
		},
		contentText: {
			title: "请帮助我们了解一下您的公司",
			txt_1:
				"想在 Azure 市场发布服务？请提交以下表单，我们将于2个工作日内联系您。",
			txt_2: "如有问题，请查阅我们的",
			txt_link1: "《服务商指南》",
			txt_3: "或",
			txt_link2: "联系我们",
			txt_4: "。",
			txt_5: "如果您已经入驻成为服务商，请登陆",
			txt_link3: "服务商门户",
			txt_6: "发布您的服务。",
		},
		uploadText: {
			addFile: "点击添加",
		},
		rules: {
			mes_1: "该项为必填项",
			mes_2: "请输入正确的邮箱地址",
			mes_3: "请至少选择一项",
			mes_4: "营业执照的格式必须为jpg或者png",
			mes_5: "尺寸必须小于2MB",
			mes_6: "请输入一个有效的Microsoft 合作伙伴网络 (MPN) ID",
			mes_7: "请输入正确的手机号",
		},
		message: {
			emailSuccess: "发送成功",
			emailError: "发送失败",
			success: "提交成功",
			error: "提交失败",
			verifyCodeError: "验证码错误。",
			uploadFileSuccess: "文件上传成功",
			uploadFileError: "文件上传失败",
		},
	},
	home: {
		bannerText: "欢迎来到Azure市场!",
		bannerText1:
			"一网打尽，一键部署，来自全球 Azure 合作伙伴生态系统的高质量应用",
		link: "查看全部",
		title_1: "热门推荐",
		title_2: "蓝云发布专区",
		title_3: "热门应用类别",
		title_4: "什么是Azure市场?",
		hot_type_1: "虚拟机映像",
		hot_type_2: "网络",
		hot_type_3: "存储",
		hot_type_4: "开发人员",
		hot_type_5: "安全性+身份",
		hot_type_6: "软件即服务（Sass）",
		hot_type_7: "数据库",
		hot_type_8: "所有类别",
		text: "由世纪互联运营的 Azure 市场是微软合作伙伴的应用和服务的统一展现门户。在这里，Azure 用户可以轻松找到、了解、尝试和部署 Azure 全球生态体系带来的高品质应用和多样化的解决方案：",
		text1: "在高速增长的中国云生态中选择最适合的解决方案",
		text2: "快速获得服务商的技术支持和定制服务",
		text3: "1元试用账号即可畅游 Azure 市场，一键部署丰富的高质量应用",
		link_1: "了解更多>",
		text4: "通过Azure市场销售您的产品",
		text5:
			"现在您就可以成为 Azure 市场的合作伙伴，向数万家 Azure 优质客户展示您的产品，提升品牌价值，用全球领先的云平台帮助您取得成功",
		btn_1: "成为合作伙伴  ",
	},
	productList: {
		product_type: "产品类别",
		screen: "筛选",
		resultText: "产品结果",
		resultText2: "个",
		unfold: "展开",
		reset: "重置筛选器",
		all: "全部",
	},
	detail: {
		r_index: "",
		r_product: "",
		top: "产品",
		uncollected: "保存供稍后使用",
		collect: "已保存",
		desc: "产品描述",
		more: "了解详细信息",
		text0: "类别",
		text1: "法律条款",
		text2: "许可协议",
		text3: "隐私策略",
		text4: "服务商",
		text5: "客服名称",
		text6: "支持",
		text7: "客服邮箱",
		text4_1: "服务专线",
		text4_2: "服务邮箱",
		text4_3: "微信",
		text4_4: "QQ",
		text4_5: "微博",
		plans_1: "软件计划",
		plans_2: "描述",
		price_1:
			"运行此产品的成本包括所选软件计划费用以及将在其上运行此软件的虚拟机的Azure基础结构成本。如果拥有企业协议或其他折扣，你的Azure基础结构价格可能将有所不同。",
		price_2: "若要查看以其他货币表示的定价，",
		price_3: "更改帐单邮寄国家/地区",
		price_4: "。成本可能会因部署区域而有所不同。",
		priceInfo: "定价信息",
		priceVaries: "已部署的模板组件的成本",
		startAndFree: "免费 最低价格",
		cost: "+ Azure 基础结构成本",
		billing: "自带许可",
		planInfo: "软件计划详细信息",
		planInfo1: "选择软件计划",
		pricingbyvirtua: "按虚拟机实例定价",
		show: "显示",
		pRecommment: "发布者建议",
		virtual: "所有虚拟机实例",
		resetFilter: "重置筛选器",
		filterDesc: "发布者建议此软件计划使用以下",
		filterDesc2: "个虚拟机实例。",
		filterDesc3: "发布者未为此部署区域推荐任何虚拟机。",
		cores: "内核(1 到 128)",
		ram: "RAM(0GB 到 3800GB)",
		mType: "虚拟机类型",
		diskSpace: "磁盘空间",
		areas: "区域",
		diskType: "驱动器类型",
		disk: "虚拟机",
		setting: "配置",
		costPerHour: "每小时成本",
		totalCost: "总成本",
		tableList: [
			"实例",
			"类别",
			"内核",
			"RAM",
			"磁盘空间",
			"驱动器类型",
			"基础结构成本",
			"软件成本",
			"每小时",
			"每个月",
		],
		chinaArea1: ["中国东部", "中国东部2", "中国北部", "中国北部2"],
		diskType_l: ["全部", "HDD", "SDD"],
		diskSpace_l: [
			"全部",
			"小（低于100GB）",
			"中等（100-600GB）",
			"大（超过600GB）",
		],
		mType_l: [
			"全部",
			"标准",
			"基本",
			"常规用途",
			"优化内存",
			"优化计算",
			"GPU",
		],
		downCsv: "下载CSV表",
		tableDesc: "*高级存储适用于此虚拟机类型。",
		tableLink: "了解详细信息",
		minPrice: "最低价格",
	},
	region: {
		title: "选择一个帐单邮寄国家/地区",
	},
	feedBack: {
		label0: "反馈",
		label1: "服务类型",
		label2: "支持类别",
		label3: "服务主题",
		label4: "服务详情",
		label4_1: "上传附件",
		label5: "姓名",
		label6: "邮箱",
		label7: "电话",
		label8: "公司名称",
		placeholder: {
			type: "请选择",
			subject: "请输入服务主题",
			details: "请输入服务详情（300字以内）",
			name: "请输入您的姓名",
			phone: "请输入您的电话号码",
			email: "请输入您的邮箱",
			company: "请输入您的公司名称",
		},
		select: {
			type1: "有关服务商入驻申请",
			// type2: '有关云市场服务商指南',
			// type3: '有关云市场页面及显示问题',
			// type4: '其他',
			type1_1: "入驻资质咨询",
			type1_2: "入驻审核问题",
			type1_3: "入驻申请进度",
			// type2_1: '文档纠错',
			// type2_2: '增添内容',
			// type2_3: '内容咨询',
			// type3_1: '页面故障排除',
			// type4_1: '其他问题',
		},
		imgSizeError: "尺寸必须小于2MB",
		desc1:
			"关于Azure中国市场的入驻申请可填写下表咨询，如果您已经是 Azure 中国市场的服务商，请通过",
		desc_link: "Azure工单",
		desc2: "寻求帮助。",
	},
	brick: {
		btn: "立即部署",
		btn2: "联系服务商",
		btn3: "详情",
		btn4: "立即购买",
		desc: "软件计划起步价",
		price: "免费",
		price1: "自带许可",
		price2: "价格不等",
	},
	//EcologicalCenter 服务商
	EcologicalCenter: {
		introduction: {
			txt_1: "云时代的生态中心，满足您拓展业务的新需求",
			txt_1_1: "",
			txt_2:
				"现在您就可以成为 Azure 市场的合作伙伴，向数万家 Azure 优质客户展示您的产品，提升品牌价值，用行业领先的云平台帮助您取得商业成功。",
			btn: "了解更多",
			txt_3: "已经入驻？",
			toPartner: "点击此处进入服务商门户",
		},
		features: {
			channel_1: "新的分发渠道",
			channel_2:
				"轻松在由世纪互联运营的业界领先Microsoft Azure 云平台上开辟新的产品分发渠道，与微软的销售团队一起拓展商机",
			deliver_1: "交付更高效",
			deliver_2:
				"通过优化的配置，部署和管理流程大幅度降低客户项目交付的成本并显著提升交付质量",
			value_1: "提升品牌价值",
			value_2:
				"向数万世纪互联运营的Microsoft Azure 优质客户展示评估和获取您的产品，优化客户体验",
		},
		maintxt: {
			start: "立刻开始",
			publish_1: "发布产品",
			publish_2:
				"如果您的产品或服务可以用来扩展、自定义，或管理 Azure，并且希望通过 Azure 市场推广和销售，请点击这里了解更多相关信息。",
			publish_btn: "发布指南",
			sales_1: "扩展和提升销售",
			sales_2:
				"通过启用销售商机管理，可以充分利用Azure市场的功能来扩展和加速您的业务；通过商业智能分析可提高业务价值并增加交易规模。",
			sales_btn: "销售指南",
		},
		application: {
			appli_1: "申请在 Azure 市场展示您的服务",
			appli_2:
				"现在就提交您的服务信息，我们的服务商入驻团队将协助您完成后续步骤。",
			appli_btn: "立即申请",
		},
	},
	//Introduction 了解更多
	Introduction: {
		market: {
			market_1: "什么是Azure市场?",
			market_2:
				"Azure 市场为 Azure 客户和服务商（ISV）之间搭建起了一站式门户， 即刻轻松体验 Azure 全球生态系统带来的高品质的应用和多样化的解决方案",
		},
		features: {
			find_1: "一应俱全",
			find_2:
				"这里有多样化的工具、应用和解决方案产品，从基础软件组件，开发运维工具，商业应用到开源软件，一应俱全。",
			try_1: "一键部署",
			try_2:
				"短短几分钟就一键部署， 可快速满足您的上云的各种需求。不论你是单个虚拟机，上百台虚拟机， 亦或是多结点的集群解决方案模板，都同样简单，一键部署。",
			deploy_1: "一站式门户",
			deploy_2:
				"提供一站式的云上市场体验，方便客户搜索，试用， 部署各种来自第三方发布商的镜像，ARM模板和定制服务。不论是 Azure 第一方的服务，还是来自第三方ISV的应用服务， 都在 Azure 门户统一呈现，可被轻松发现和一键部署，让您能够充分享受云平台的可扩展性。",
			integration_1: "一以贯之",
			integration_2:
				"秉承与全球 Azure 市场一贯的简洁风格，全新的用户体验让人耳目一新，方便 Azure 用户在丰富的生态中迅速定位到自己所需的应用和服务。",
		},
	},
	//Login 登录
	Login: {
		login_1: "登录 AZURE 市场",
		login_2: "使用 Azure 帐户",
		btn: "登录",
		login_3: "没有账户?",
		login_4: "注册",
	},
	Info: {
		contact: "联系我们",
		title: "请输入信息",
		name: "姓名",
		email: "邮箱",
		phone: "联系电话",
		company: "公司名称",
		consult: "咨询",
		submit: "提交",
		placeholder_c: "请输入咨询内容",
		text: "Azure中国市场不支持收集潜在客户信息，请通过发布者在产品描述中提供的联系信息联系发布者。",
	},
	productDialog: {
		t1: [
			"我同意服务商的",
			"使用条款",
			"和",
			"隐私策略",
			"，并理解世纪互联不授予使用此产品的权利。Azure 市场的使用受到单独的",
			"条款",
			"的约束。",
		],
		btn: "继续",
		price: "定价",
		detail: "详细信息",
		creatApp: "在 Azure 中创建此应用",
		softPlan: "软件计划",
		alert: [
			"请您知悉，",
			"Azure市场上",
			"的产品或服务（”非世纪互联产品“）非由世纪互联提供，由发布商基于单独的条款提供，世纪互联不对非世纪互联产品提供任何承诺或保证，也不对非世纪互联产品承担任何义务或责任。",
		],
	},

	favourites: {
		title: "已保存的应用",
		text: "尚未保存任何应用",
	},
};
